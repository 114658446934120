<template>
	<div class="container test-page" ref="testContainer">
		<div class="question">
			<div class="title">
				<h2>{{name}}</h2>
				<p>{{term}}</p>
			</div>
			<div class="naire">
				<el-divider v-if="topicList.singleAnswer.length > 0">单选题 <span
						v-if="tp != 400">(共{{ totalScore.singleAnswer }}分)</span> </el-divider>
				<el-form>
					<el-form-item v-for="(item, idx) in topicList.singleAnswer" :key="idx">
						<h4>{{ idx + 1 }}. <span v-html="item.topic"></span> <span v-if="tp != 400">({{ item.topicScore }}分)</span> </h4>
						<div class="topic-media" v-if="tp == 402">
							<media-exhi v-if="item.imgUrl" :fileData="item.imgUrl"></media-exhi>
						</div>
						<div class="options-list">
							<div class="options-item" v-for="(item1, idx1) in item.topicDetails">
								<div class="left-side">
									<div class="sel">
										<div class="circle-icon with-pointer" :class="{ 'selected': item._answer.indexOf(item1.topicOptions) != -1 }" @click="selOpt('singleAnswer', idx, item1)">
											<i class="el-icon-check"></i>
										</div>
									</div>
								</div>
								<div class="right-side">
									<div class="opt-cont" v-html="item1.topicOptionsContent"></div>
									<media-exhi v-if="tp == 402 && item1.imgUrl" :fileData="item1.imgUrl" size="50px"></media-exhi>
								</div>
							</div>
						</div>
					</el-form-item>
				</el-form>
				<el-divider v-if="topicList.multiAnswer.length > 0">多选题 <span
						v-if="tp != 400">(共{{ totalScore.multiAnswer }}分)</span></el-divider>
				<el-form>
					<el-form-item v-for="(item, idx) in topicList.multiAnswer" :key="idx">
						<h4>{{ idx + 1 }}. <span v-html="item.topic"></span> <span v-if="tp != 400">({{ item.topicScore }}分)</span> </h4>
						<div class="topic-media" v-if="tp == 402">
							<media-exhi v-if="item.imgUrl" :fileData="item.imgUrl"></media-exhi>
						</div>
						<div class="options-list">
							<div class="options-item" v-for="(item1, idx1) in item.topicDetails">
								<div class="left-side">
									<div class="sel">
										<div class="circle-icon" :class="{ 'selected': item._answer.indexOf(item1.topicOptions) != -1 }" @click="selOpt('multiAnswer', idx, item1)">
											<i class="el-icon-check"></i>
										</div>
									</div>
								</div>
								<div class="right-side">
									<div class="opt-cont" v-html="item1.topicOptionsContent"></div>
									<media-exhi v-if="tp == 402 && item1.imgUrl" :fileData="item1.imgUrl" size="50px"></media-exhi>
								</div>
							</div>
						</div>
					</el-form-item>
				</el-form>
				<p style="font-size:14px;color: red">注：提交后测评结果不可修改，请确认无误后再进行提交！</p>
			</div>
		</div>
		<div class="btn-box">
			<el-button @click="backIndex">关 闭</el-button>
			<el-button type="primary" @click="beforeSubmit">确定</el-button>
		</div>
		<div class="footer">
			<div class="footer-content">
				<!-- <div class="footer-center">
					<p>版权所有：秦皇岛市教育局</p>
					<p>地址：河北省秦皇岛市海港区秦皇东大街485号</p>
				</div> -->
				<div class="footer-center">
					<p>违法和不良信息举报电话：0311-86036653</p>
					<p>E-mail：sjzedu@sjy.net.cn</p>
					<p>技术支持：师达教育</p>
				</div>
			</div>
		</div>
		<close-and-go-back></close-and-go-back>
	</div>
</template>

<script>
	import {
		getQuestionNaire,
		submitTest,
		getEvaTopics,
		submitEvaTopics
	} from '@/api/student/myquality/shenxin'
	import closeAndGoBack from '@/components/closeAndGoBack.vue'
	import mediaExhi from "@/components/mediaExhibition.vue"
	import { makeImgEnlarge } from "@/mixin/makeImgPreview.js"
	export default {
		components: {
			closeAndGoBack,
			mediaExhi
		},
		mixins: [ makeImgEnlarge ],
		data() {
			return {
				name: '',
				term: '',
				form: {
					questionNaire: [],
				},
				topicList: {
					singleAnswer: [],
					multiAnswer: []
				},
				totalScore: {
					singleAnswer: 0,
					multiAnswer: 0
				},
				params: {},
				pageH: 0
			}
		},
		created() {
			document.documentElement.scrollTop = 0
			this.getParams()
		},
		mounted() {
			let container = document.documentElement
			this.pageH = container.clientHeight
		},
		computed: {
			tp() {
				return this.params.evaTypeId
			}
		},
		methods: {
			getParams() {
				this.params = this.$route.params.name != undefined ? this.$route.params : JSON.parse(sessionStorage['params'])
				this.name = this.params.name
				this.term = this.params.term
				this.getQuestion()
			},
			// 获取试题
			getQuestion() {
				getEvaTopics({
					evaTypeId: this.params.evaTypeId
				}).then(res => {
					let d = res.data
					// 心理试题
					let psy = d.psychologyTopics
					// 艺术 / 健康的单选题
					let ha1 = d.healthArtTopicsResultByRadio
					// 艺术 / 健康的多选题
					let ha2 = d.healthArtTopicsResultByChoice
					// 批量添加答案字段
					let addAnswerFormItem1 = v => v._answer = ''
					let addAnswerFormItem2 = v => v._answer = []
					// 算总分
					let calcTotalScore = (a, b) => a + b.topicScore
					if (psy.length > 0) {
						psy.forEach(addAnswerFormItem1)
					}
					if (ha1.length > 0) {
						ha1.forEach(addAnswerFormItem1)
						this.totalScore.singleAnswer = ha1.reduce(calcTotalScore, 0)
					}
					if (ha2.length > 0) {
						ha2.forEach(addAnswerFormItem2)
						this.totalScore.multiAnswer = ha2.reduce(calcTotalScore, 0)
					}
					// 心理试题只有单选
					if (this.tp == 400) {
						this.topicList.singleAnswer = psy
					} else if (this.tp == 401) {
						this.topicList.singleAnswer = ha1
						this.topicList.multiAnswer = ha2
					} else if (this.tp == 402) {
						this.topicList.singleAnswer = ha1
						this.topicList.multiAnswer = ha2
					}
				})
			},
			// 答题
			selOpt(topicTp, idx, item1) {
				if (topicTp == 'singleAnswer') {
					// 单选题直接覆盖已有选项
					this.topicList[topicTp][idx]._answer = item1.topicOptions
				} else if (topicTp == 'multiAnswer') {
					// 已选择的答案
					let answerArr = this.topicList[topicTp][idx]._answer
					// 增加选项
					if (answerArr.indexOf(item1.topicOptions) == -1) {
						answerArr.push(item1.topicOptions)
					} else {
						// 删掉选项
						answerArr = answerArr.filter(v => v != item1.topicOptions)
					}
					this.topicList[topicTp][idx]._answer = answerArr
				}
			},
			// 问卷提交前校验
			beforeSubmit() {
				let noAnswer = {
					single: [],
					multi: []
				}
				// 单选题未作答的题
				this.topicList.singleAnswer.forEach((v, i) => {
					if (v._answer == '') {
						// 存储行号
						noAnswer.single.push(i + 1)
					}
				})
				if (this.tp == '400') {
					if (noAnswer.single.length > 0) {
						this.$message.error(`第${ noAnswer.single.join('、') }题没有作答`)
					} else {
						this.submitEvas()
					}
				} else if (this.tp == '401' || this.tp == '402') {
					let tips = ''
					// 多选题未作答的题
					this.topicList.multiAnswer.forEach((v, i) => {
						if (v._answer.length == 0) {
							noAnswer.multi.push(i + 1)
						}
					})
					// 组织提示用语
					if (noAnswer.single.length > 0 && noAnswer.multi.length == 0) {
						tips = `单选题第${ noAnswer.single.join('、') }`
					} else if (noAnswer.single.length == 0 && noAnswer.multi.length > 0) {
						tips = `多选题第${ noAnswer.multi.join('、') }`
					} else if (noAnswer.single.length > 0 && noAnswer.multi.length > 0) {
						tips = `单选题第${ noAnswer.single.join('、') }题，多选题第${ noAnswer.multi.join('、') }`
					}
					if (tips != '') {
						this.$message.error(`${tips}题没有作答`)
					} else {
						this.submitEvas()
					}
				}

			},
			// 提交问卷
			submitEvas() {
				let tempList = []
				if (this.topicList.singleAnswer.length > 0) {
					this.topicList.singleAnswer.forEach((v, i) => {
						tempList.push({
							topicId: v.id,
							dimensionManagerId: v.dimensionManagerId,
							answer: v._answer
						})
					})
				}
				if (this.topicList.multiAnswer.length > 0) {
					this.topicList.multiAnswer.forEach((v, i) => {
						tempList.push({
							topicId: v.id,
							dimensionManagerId: v.dimensionManagerId,
							answer: v._answer.join(',')
						})
					})
				}
				submitEvaTopics({
					evaId: this.params.id,
					submits: tempList
				}).then(res => {
					this.$message.success('测评提交成功')
					this.backIndex()
				})
			},
			backIndex() {
				this.$router.go(-1)
			},
			// 到顶
			toTop() {
				document.documentElement.scrollTop = 0
			},
			// 到底
			toBottom() {
				// console.log(this.pageH)
				// window.scrollTo(0, this.pageH)
				document.documentElement.scrollTop = this.pageH
			}
		},
	}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/test-page.scss";
</style>