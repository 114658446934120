let makeImgEnlarge = {
	methods: {
		// 替换img标签为el-image， 添加el-image预览功能
		_replaceImg2ElImg (cont) {
			let ret = cont
			let _reg = {
				tag: /<img.*?(?:>|\/>)/gi,
				src: /src=[\'\"]?([^\'\"]*)[\'\"]?/i
			}
			// 匹配文本中的所有图片
			let allImg = cont.match(_reg.tag)
			console.log(allImg)
			
			if (Array.isArray(allImg) && allImg.length > 0) {
				let srcList = []
				// 筛出所有图片的src，方便预览使用
				allImg.forEach(v => {
					srcList.push(v.match(_reg.src)[1])
				})
				// 替换为el-image
				allImg.forEach(v => {
					let curSrc = v.match(_reg.src)[1]
					ret = cont.replace(v, `<el-image src="${ curSrc }" style="display: inline-block; width: 100px; height: 100px;"></el-image>`)
				})
			}
			return ret
		}
	}
}
export { makeImgEnlarge }